<template>
  <div class="app">

    <el-card class="box-card">
      <!--列表-->
      <div>
        <div class="listsHeader">
          <el-row :gutter="24">

            <el-col :span="12">
              <el-popover placement="top" width="160" v-model="delAllStatus" style="margin-right: 8px;">
                <p>您確認要恢復賬戶嗎？</p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="delAllStatus = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="doRestoreAll">確定</el-button>
                </div>
                <el-button size="small" type="success" slot="reference">
                  <i class="fa fa-history"></i>
                  批量恢復
                </el-button>
              </el-popover>

              <el-button size="small" type="info" @click="$refs.RoomTable.clearSelection()" style="margin-right: 8px;">
                取消選擇
              </el-button>


            </el-col>

            <el-col :span="12">
              <el-input placeholder="填寫查詢內容" size="small" v-model="query" class="employees-input">
                <el-select v-model="type" slot="prepend" placeholder="請選擇">
                  <el-option label="所有" value="0"></el-option>
                  <el-option label="賬戶號碼" value="1"></el-option>
                  <el-option label="手提號碼" value="2" style="margin-bottom: 20px;"></el-option>
                </el-select>
                <el-button slot="append" @click="queryBin">
                  <i class="fa fa-search"></i>
                </el-button>
              </el-input>
            </el-col>

          </el-row>
        </div>
        <el-table
            class="lists"
            ref="RoomTable"
            :data="lists.list"
            :height="lists.total?clientHeight:'100'"
            v-loading="loading"
            @selection-change="handleSelectionChange"
            border
            style="width: 100%;">
          <el-table-column
              fixed
              type="selection"
              width="40">
          </el-table-column>
          <el-table-column
              fixed
              label="頭像"
              width="90">
            <template slot-scope="scope">
              <img
              class="table-user-head"
              :src="scope.row.avatarUrl?scope.row.avatarUrl:userHeadImg" />
            </template>
          </el-table-column>
          <el-table-column
              fixed
              prop="account"
              min-width="120"
              label="賬戶號碼">
          </el-table-column>
          <el-table-column
              prop="name"
              min-width="120"
              label="用戶名稱">
          </el-table-column>
          <el-table-column
              prop="phone"
              label="手提號碼"
              width="120">
          </el-table-column>
          <el-table-column
              label="性别"
              width="80">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-tag :type="sexColor[scope.row.sex]">
                  {{sexType[scope.row.sex]}}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="groupId"
              label="權限組"
              width="120">
          </el-table-column>
          <el-table-column
              label="鎖定狀態"
              width="80">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-switch v-model="scope.row.isLock" active-color="#ff4949" inactive-color="#eee" disabled>
                </el-switch>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              label="登入次數"
              width="100">
            <template slot-scope="scope">
              <div style="text-align: center;">
                {{scope.row.logEntry}} 次
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="logIp" label="登入IP" width="120"></el-table-column>
          <el-table-column
              prop="logTime"
              label="最新登入時間"
              width="180">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="130">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="small" type="success" @click="restoreBtn(scope.row.id)" style="margin-right: 8px;">
                  <i class="fa fa-history"></i>
                  恢復
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pager">
          <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :page-size="lists.pageSize"
              :total="lists.total">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import defaultUserHead from '../../assets/img/admin/user/2.jpg'
export default {
  data () {
    return {
      userHeadImg:defaultUserHead,
      clientHeight:800,
      type:"0",
      query:"",
      sexType:['保密','男','女'],
      sexColor:['info','','danger'],
      lists:{
        total:0,
        pageSize:0,
        list:[]
      },
      multipleSelection:[],
      page:1,
      delAllStatus:false,
      delAllStatus2:false,
      loading:false,
    }
  },
  mounted() {
    let that = this
    that.getList()
  },
  methods:{
    // 获取账户列表
    getList(page=0){
      let that = this
      let param=that.$atk.json_to_url({
        page:page||parseInt(that.page),
        type:parseInt(that.type),
        query:that.query
      })
      that.loading=true
      this.$http.GET('/v1/admin/getTimeMachineList?'+param,function(ret){
        if(ret.errcode == 0){
          let data = ret.data || []
          for(let i in data.list){
            data.list[i].sex = data.list[i].sex.toString()
            data.list[i].isLock = data.list[i].isLock?true:false
          }
          that.lists = data
          that.loading=false
        }else if(ret.errcode == 1){
          that.loading=false
          that.$message({
            message: ret.errmsg,
            type: 'warning'
          });
        }
      },function(err){
        that.loading=false
        that.$notify({
          title: '警告',
          message: err,
          type: 'warning'
        });
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val
      this.getList(val)
    },
    // 恢复按钮
    restoreBtn(id){
      let that = this
      that.$confirm('您确认要恢复此账户吗?', '恢复提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.doRestore([id])
      }).catch(() => {
        that.$notify({
          title: '恢复提示',
          message: '已取消恢复用户',
          type: 'warning'
        });
      });
    },
    // 恢复用户
    doRestore(arrayId){
      let that = this
      let param = that.$atk.json_to_url({
        ids:arrayId
      })
      that.$http.PUT('/v1/admin/restoreMember?'+param,{},function(ret){
        if(ret.errcode == 0){
          that.$notify({
            title: '成功',
            message: ret.errmsg,
            type: 'success'
          });
          for (let key in arrayId) {
            that.deleteItem(arrayId[key],that.lists.list)
          }
        }else if(ret.errcode == 1){
          that.$notify.error({
            title: '错误',
            message: ret.errmsg,
          });
        }
      },function(err){
        that.$notify({
          title: '警告',
          message: err,
          type: 'warning'
        });
      })
    },
    // 删除列表用户
    deleteItem(item, list){
      for (let key in this.lists.list) {
        if (list[key].id === item) {
          list.splice(key, 1)
        }
      }
    },
    // 选择用户
    handleSelectionChange(val) {
      this.multipleSelection = val//.filter(val => val.id!=1)
      let row = val.filter(val => val.id==1)
      this.$refs.RoomTable.toggleRowSelection(row[0],false)
    },
    // 恢复已选择用户
    doRestoreAll(){
      let that = this
      let length = that.multipleSelection.length
      if(length<=0){
        that.$notify({
          title: '恢复提示',
          message: '请勾选恢复用户',
          type: 'warning'
        });
        return
      }
      let idList=[]
      for(let i in that.multipleSelection){
        idList[i]= that.multipleSelection[i].id
      }
      that.doRestore(idList)
      that.delAllStatus=false
      //that.$notify({
      //  title: '恢复提示',
      //  message: '恢复用户成功',
      //  type: 'success'
      //});
    },
    // 查询
    queryBin(){
      let that = this
      if(!that.query){
        that.$message({
          message: "请输入查询内容",
          type: 'warning'
        });
        return
      }
      this.getList()
    }
  },
}
</script>
<style scoped lang="scss">
.box-card{
  min-height: 10px;
  .listsHeader{
    min-height: 50px;
  }
  .pager{
    padding-top: 8px;
  }
}
.employees-input{
  button{
    height: 100%;
    padding: 9px 20px;
    border-radius: 0px 3px 3px 0px;
    color: #fff;
    background-color: #25334e;
  }
  button:hover{
    background-color: #182132;
  }
}
.table-user-head{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
/* 列表 */
.listsHeader{
  .el-input-group__prepend .el-select {
    width: 90px;
  }
}
/* 编辑或者添加 */
.member-ruleForm{
  .avatar-uploader {
    .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
      border-radius: 6px;
    }
  }
}
</style>
